<template>
     <Reset/>
</template>

<script>
// @ is an alias to /src
import Reset from '@/components/login/Reset.vue'

export default 
{

  components: 
  {
    Reset
  }
}
</script>
