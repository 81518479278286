<template>
  <div class="content" v-if="showContent != 'false'">
    <main id="e" class="flex-shrink-0 form-signin">
      <div class="content p-4 box-login shadow">
        <form @submit.prevent="Forgot()" method="post">
          <img class="mb-0" :src="require('@/assets/img/login.png')" style="width:50%;">
          <br>
          <img class="mb-1" :src="require('@/assets/img/brand.png')" style="width:50%;">
          <div id="text">
            <h4 class="text-color-1 mb-3 fw-bold">Enter your new password</h4>
            <div class="text-start">
              <label class="text-color-1">Password</label>
              <input type="password" v-model="newPass.password" class="form-control" id="password" placeholder="Password" minlength="8" required="required" @keyup="EnabledButton">
            </div>
            <div class="text-start">
              <label class="text-color-1">Confirm Password</label>
              <input type="password" v-model="newPass.password2" class="form-control" id="password2" placeholder="Password" minlength="8" required="required" @keyup="EnabledButton">
              <ul>
                <li v-bind:class="{ is_valid: PassValidate.contains_eight_characters }"><small>8 Characters</small></li>
                <li v-bind:class="{ is_valid: PassValidate.contains_number }"><small>Contains Number</small></li>
                <li v-bind:class="{ is_valid: PassValidate.contains_uppercase }"><small>Contains Uppercase</small></li>
                <li v-bind:class="{ is_valid: PassValidate.contains_special_character }"><small>Contains Special Character</small></li>
                <li v-bind:class="{ is_valid: PassValidate.match_pass_confirm }"><small>Password and Comfirm Password must be match</small></li>
              </ul>
            </div>
            <button id="send-button" class="button-1 w-100 btn btn-primary" type="submit" disabled>Reset password</button>
            <hr>
            <div v-if="response.status == true" class="alert alert-success d-flex align-items-center" role="alert">
              <div>
                {{response.message}}
              </div>
            </div>
            <div v-if="response.status == false" class="alert alert-danger d-flex align-items-center" role="alert">
              <div>
                {{response.message}}
              </div>
            </div>
          </div>
        </form>
        <div class="checkbox mb-3"></div>
        <span class="text-color-1">You have account? <router-link to="/login" class="link-color fw-bold">Login</router-link> 
        </span>
      </div>
      <p class="mt-5 mb-3 text-muted">© Work Mileage 2022  </p>
    </main>
  </div>
</template>

<script>
  import Api from '@/services/Api';

  export default 
  {
    data: () => (
    {
      newPass: 
      {
        password: "",
        password2: "",
        code: ""
      },
      showContent: 'false',
      PassValidate:
      {
        password: null,
        password2: null,
        password_length: 0,
        contains_eight_characters: false,
        contains_number: false,
        contains_uppercase: false,
        contains_special_character: false,
        match_pass_confirm: false,
        valid_password: false
      },
      response: 
      {
        message: "",
        status: "Empty"
      },
      resultMsg: "",
      PostCode: 
      {
        code: ''
      },
    }),
    methods: 
    {
      Forgot() 
      {
        this.newPass.code = this.$route.params.code;
        Api.post("login/reset",this.newPass).then((result) => 
        { 
          if(result.data.status == "true")
          {
            let message = result.data.msg
            this.$moshaToast( ''+message+'', { type: 'default', position:'bottom-right', transition:'zoom' })
            document.getElementById("text").innerHTML = '<h5 class="text-color-1 mb-3">Log in to the app using your new password</h5>';
          }
          
          if(result.data.status == "false")
          {
            let message = result.data.msg
            this.$moshaToast( ''+message+'', { type: 'default', position:'bottom-right', transition:'zoom' })
          }
        })
      },
      //Enabled/Disabled send button
      EnabledButton()
      {
        this.checkPassword();

        if(this.PassValidate.valid_password  == true )
        {
          document.getElementById("send-button").disabled = false;
        }
        else
        {
          document.getElementById("send-button").disabled = true;
        }
      },
      checkPassword() 
      {
        this.PassValidate.password = this.newPass.password;
        this.PassValidate.password2 = this.newPass.password2;
        this.PassValidate.password_length = this.PassValidate.password.length;
        const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      
        if (this.PassValidate.password_length >= 8) 
        {
          this.PassValidate.contains_eight_characters = true;
        } 
        else 
        {
          this.PassValidate.contains_eight_characters = false;
        }

        //Confirm Pass
        if (this.PassValidate.password2 == this.PassValidate.password) 
        {
          this.PassValidate.match_pass_confirm = true;
        } 
        else 
        {
          this.PassValidate.match_pass_confirm = false;
        }
      
        this.PassValidate.contains_number = /\d/.test(this.PassValidate.password);
        this.PassValidate.contains_uppercase = /[A-Z]/.test(this.PassValidate.password);
        this.PassValidate.contains_special_character = format.test(this.PassValidate.password);
      
        if (this.PassValidate.contains_eight_characters === true && this.PassValidate.contains_special_character === true && this.PassValidate.contains_uppercase === true && this.PassValidate.contains_number === true && this.PassValidate.match_pass_confirm === true) 
        {
          this.PassValidate.valid_password = true;      
        } 
        else 
        {
          this.PassValidate.valid_password = false;
        }
      },
      VerificationCode() 
      {
        this.PostCode.code = this.$route.params.code;

        Api.post("/login/verifycode",this.PostCode).then((result) => 
        {
          if(result.data.status == 'true')
          {
            //this.$router.push({ name: "ResetPassword", params: { code: result.data.msg } });
            this.showContent = "true";
          }
          else
          {
            this.$router.push({ name: "NotFound" });
          }
        })
      }
    },
    mounted()
    {
      this.VerificationCode()
    }
  }
</script>

<style>
.is_valid { color: rgba(136, 152, 170, 0.8);    text-decoration: line-through green; }
.is_valid:before { width: 100%; }

.link-color
{
    color: var(--color-three);
    text-decoration: none;
}
.content 
{
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
.box-login
{
    background-color: var(--color-two);  
    border-radius: 5px;  
}
.text-color-1
{
    color:var(--color-one);
}
.form-signin 
{
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.button-1
{
    background-color: var(--color-one);
    border:1px solid var(--color-one);
}
.button-1:hover
{
    background-color: var(--color-one);
    border:1px solid var(--color-one);
}

.form-signin .checkbox 
{
  font-weight: 400;
}

.form-signin input[type="text"] 
{
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--color-five);
  border: 1px solid var(--color-five);
}

.form-signin input[type="email"] 
{
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--color-five);
  border: 1px solid var(--color-five);
}

.form-signin input[type="password"] 
{
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--color-five);
  border: 1px solid var(--color-five);
}
.bd-placeholder-img 
{
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  @media (min-width: 768px) 
  {
    .bd-placeholder-img-lg 
    {
      font-size: 3.5rem;
    }
  }

.h4, h4 
{
    font-size: calc(1rem + .3vw);
}
</style>
<!--<style scoped>
.link-color
{
  color: var(--color-three);
  text-decoration: none;
}
.content 
{
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
.box-login
{
    background-color: var(--color-two);  
    border-radius: 5px;  
}
.text-color-1
{
    color:var(--color-one);
}
.form-signin 
{
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.button-1
{
    background-color: var(--color-one);
    border:1px solid var(--color-one);
}
.button-1:hover
{
    background-color: var(--color-one);
    border:1px solid var(--color-one);
}

.form-signin .checkbox 
{
  font-weight: 400;
}

.form-signin input[type="text"] 
{
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--color-five);
  border: 1px solid var(--color-five);
}

.form-signin input[type="email"] 
{
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--color-five);
  border: 1px solid var(--color-five);
}

.form-signin input[type="password"] 
{
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--color-five);
  border: 1px solid var(--color-five);
}
.bd-placeholder-img 
{
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  @media (min-width: 768px) 
  {
    .bd-placeholder-img-lg 
    {
      font-size: 3.5rem;
    }
  }

.h4, h4 
{
    font-size: calc(1rem + .3vw);
}
</style>-->
